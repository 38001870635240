import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import '@fullcalendar/resource-timeline/main.css';
import Component from 'vue-class-component';
import nlLocale from '@fullcalendar/core/locales/nl';
import { bookingService, dateHelper, placeSearchHelper, searchService, siteService, loginService, loginHelper } from '@/main';
import to from 'await-to-js';
import PlanBoardResponse from '../../models/PlanBoard/Response';
import PlanBoardResourceChild from '../../models/PlanBoard/ResourceChild';
import PlanBoardResources from '../../models/PlanBoard/Resources';
import PlanBoardEvent from '../../models/PlanBoard/Event';
import PageRender from '../../models/PageRender';
import { SearchPlaceTypeParamsInterface } from '../../models/Interfaces';
import { Watch } from 'vue-property-decorator';
import { SearchResponse } from '../../models/Search/Respone';
import Site from '../../models/Site';
import Place from '../../models/Place';
import { AxiosResponse } from 'axios';
import { $router } from '@/router';
import Booker from '@/models/Booker';
import PlaceType from '@/models/PlaceType';
import Booking from '@/models/Booking';
import moment from 'moment/min/moment.min.js';
import PlanBoardPlace from '@/models/PlanBoard/Place';
import { RentalState } from '@/models/Place/RentalState';
@Component({
    components: {
        FullCalendar,
    },
})
export default class Planbord extends PageRender {
    public isLoading: boolean = true;
    public isSearching: boolean = true;
    public isLoadingResourcesAndEvents: boolean = true;
    public resources: PlanBoardResources[] = [];
    public events: PlanBoardEvent[] = [];
    public showFilter: boolean = false;
    public selectedFacets: any = {};
    public places: Place[] = [] as Place[];
    public placeTypes: PlaceType[] = [] as PlaceType[];
    public monthAddition: number = 0;
    public searchParams: SearchPlaceTypeParamsInterface = {
        site: null,
        siteOwner: loginHelper.getSiteOwner(),
        fromDate: dateHelper.format(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
        toDate: dateHelper.format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)),
        capacity: null,
        fromCapacity: 1,
        toCapacity: 99,
        facets: '',
        Senior: 0,
        Adult: 2,
        ChildThreeYearsAndUp: 0,
        ZeroUntilTwoYears: 0,
        Pet: 0,
        petsAllowed: false,
        disabledAccessible: false,
    };
    public $refs!: {
        fullCalendar: any;
    };
    public allFacets: any = {};
    public maxCapacity: number = null;
    public calendarOptions = {
        initialView: 'resourceTimelineMonth',
        plugins: [resourceTimelinePlugin, interactionPlugin],
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        locale: nlLocale,
        resources: [],
        resourceOrder: 'title',
        events: [],
        select: this.handleSelect,
        selectable: true,
        height: 'auto',
        headerToolbar: false,
    };
    public calendarKey: number = 0;
    public hideBlocked = true;
    private site: Site = new Site();
    private searchResponse: SearchResponse = null;
    private availablePlaceTypes: number[] = [];
    private planBoardResponse: PlanBoardResponse = null;

    public get monthTitle() {
        return new Date(this.searchParams.fromDate).toLocaleDateString('nl', {
            month: 'long',
            year: 'numeric',
        });
    }

    @Watch('searchParams', { deep: true, immediate: false })
    public async watchSearchParams() {
        await this.getAvailableAccommodations();
        await this.loadResourcesAndEvents();
    }

    public previousMonth() {
        this.monthAddition--;
        this.setFromAndToDate();
        this.$refs.fullCalendar.getApi().prev();
    }

    public nextMonth() {
        this.monthAddition++;
        this.setFromAndToDate();
        this.$refs.fullCalendar.getApi().next();
    }

    public today() {
        this.monthAddition = 0;
        this.setFromAndToDate();
    }
    public async created() {
        const [err, response] = await to(siteService.getSite(this.siteId));
        this.site = response.data;

        this.allFacets = await this.getAllFacets();
        this.places = await this.getPlaces();
        this.placeTypes = await this.getPlaceTypes();

        this.searchParams.site = this.site.siteId;
        this.getAvailableAccommodations();
        this.isLoading = false;
    }

    public async getAllFacets() {
        const [err, response] = await to(
            searchService.facets({
                site: null,
                siteOwner: loginHelper.getSiteOwner(),
            } as SearchPlaceTypeParamsInterface),
        );

        this.setMaxCapacity(response.data.accommodationTypes);

        return response.data.facets;
    }

    public async getPlaces() {
        const [err, placesResponse] = await to<AxiosResponse<Place[]>>(siteService.getPlaces(this.siteId));
        if (err) {
            this.showError('Mislukt om accommodaties op te halen');
        }
        return (this.places = placesResponse.data);
    }

    public async getPlaceTypes(): Promise<PlaceType[]> {
        const [err, response] = await to(siteService.getPlaceTypes(this.siteId));
        if (err) {
            this.showFailedResponse('Mislukt om accomodatie types op te halen', err);
            return [];
        }
        return (this.placeTypes = response.data);
    }

    public toggleFilter(): void {
        this.showFilter = !this.showFilter;
    }

    public handleSelect(event): void {
        const place = this.places.find((p) => p.placeId === parseInt(event.resource.id, 10));
        const params = {
            fromDate: event.startStr,
            toDate: event.endStr,
        };

        const query: any = { placeTypeIds: JSON.stringify([place.placeTypeId]), placeId: place.placeId, site: this.siteId };
        Object.assign(query, params);

        $router.push({ name: 'create-booking', query });
    }

    public initResourcesAndEvents() {
        this.initResources(this.planBoardResponse);
        this.initEvents(this.planBoardResponse);
    }

    private setMaxCapacity(types) {
        let biggestCapacity = 0;
        types.forEach((type) => {
            if (biggestCapacity < type.capacity) {
                biggestCapacity = type.capacity;
            }
        });
        this.maxCapacity = biggestCapacity;
    }

    private async loadResourcesAndEvents(start = '', end = ''): Promise<boolean> {
        this.isLoadingResourcesAndEvents = true;

        if (!start) {
            start = this.searchParams.fromDate;
        }

        if (!end) {
            end = this.searchParams.toDate;
        }
        const [error, response] = await to(bookingService.getPlanBoard(this.site.siteId, start, end));

        if (error) {
            this.clearAndShowError('Mislukt om gegevens van het planboard op te halen.', error);
            this.isLoadingResourcesAndEvents = false;
            return false;
        }

        this.planBoardResponse = response.data as PlanBoardResponse;
        this.initResourcesAndEvents();

        this.isLoadingResourcesAndEvents = false;
    }

    private initResources(planBoardResponse: PlanBoardResponse) {
        if (!planBoardResponse.groups.length) {
            return;
        }

        this.resources = [];

        const sortedGroups = planBoardResponse.groups.sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        for (let i = 0; i < sortedGroups.length; i++) {
            const children = [];
            const group = sortedGroups[i];
            let placeType = null;

            if (this.showFilter && this.availablePlaceTypes) {
                placeType = this.placeTypes
                    .filter((type) => this.availablePlaceTypes.indexOf(type.placeTypeId) > -1)
                    .find((type: PlaceType) => {
                        return type.placeTypeId === group.placeTypeId;
                    });
            } else {
                placeType = this.placeTypes.find((type: PlaceType) => {
                    return type.placeTypeId === group.placeTypeId;
                });
            }

            if (placeType) {
                sortedGroups[i].entries.forEach((entry: PlanBoardPlace) => {
                    if (placeType.placeTypeId === group.placeTypeId) {
                        if (this.showResource(entry)) {
                            children.push({
                                id: entry.id,
                                title: entry.name,
                            } as PlanBoardResourceChild);
                        }
                    }
                });

                this.resources.push({
                    id: 'group' + i,
                    title: sortedGroups[i].name,
                    children,
                });
            }
        }
        this.calendarOptions.resources = this.resources;
    }

    private initEvents(planBoardResponse: PlanBoardResponse) {
        if (!planBoardResponse.groups.length) {
            return;
        }

        this.events = [];
        planBoardResponse.groups.forEach((group) => {
            group.entries.forEach((entry) => {
                entry.events.forEach((event) => {
                    const planBoardEvent: PlanBoardEvent = {
                        id: event.id,
                        eventType: event.eventType,
                        title: event.title,
                        start: dateHelper.format(event.beginDate, 'YYYY-MM-DD'),
                        end: dateHelper.format(moment(event.endDate).add(1, 'day'), 'YYYY-MM-DD'),
                        resourceId: entry.id,
                        url: '',
                        backgroundColor: '#dc3545',
                        borderColor: '#dc3545',
                        eventDisplay: 'block',
                    };

                    if (event.booking) {
                        const bookerObj = new Booker(event.booking.mainBooker);
                        event.booking.source = event.booking.source.toLowerCase() === 'recreapiwebinterface' ? 'Website' : event.booking.source;
                        const bookingSource = event.booking.source.toLowerCase() !== 'frontoffice' ? 'Via ' + event.booking.source : '';

                        planBoardEvent.title = `
                           BK-${event.booking.bookingId} -
                            ${bookingSource} -
                            ${bookerObj.name()}`;

                        planBoardEvent.url = `/${this.siteId}-${this.siteKey}/boekingen/${event.booking.bookingId.toString()}`;
                        planBoardEvent.booking = event.booking;

                        if (planBoardEvent.booking) {
                            const booking = new Booking(planBoardEvent.booking);
                            let color = '#28a745';

                            switch (booking.bookingState) {
                                case 1:
                                case 2:
                                    color = '#ffc107';
                                    break;
                                case 4:
                                case 8:
                                    color = '#dc3545';
                            }
                            planBoardEvent.backgroundColor = color;
                            planBoardEvent.borderColor = color;
                        }
                    }

                    this.events.push(planBoardEvent);
                });
            });
        });
        this.calendarOptions.events = this.events;
    }

    private async getAvailableAccommodations() {
        if (!placeSearchHelper.validateSearchParams(this.searchParams)) {
            return;
        }

        this.isSearching = true;
        const [err, response] = await placeSearchHelper.searchAvailablePlaceTypes(this.searchParams);
        this.isSearching = false;
        if (response && response.data) {
            this.searchResponse = response.data;
            this.availablePlaceTypes = this.searchResponse.accommodationTypes.map((type) => type.placeTypeId);
        }
    }

    private setFromAndToDate() {
        this.searchParams.fromDate = dateHelper.format(new Date(new Date().getFullYear(), new Date().getMonth() + this.monthAddition, 1));
        this.searchParams.toDate = dateHelper.format(new Date(new Date().getFullYear(), new Date().getMonth() + 1 + this.monthAddition, 1));
    }

    private hasFreeSpot(entry: PlanBoardPlace): boolean {
        let available = true;

        for (let i = 0; i < entry.events.length; i++) {
            const event = entry.events[i];
            const form = new Date(event.beginDate);
            const toDate = new Date(event.endDate);

            if (form.getTime() <= new Date(this.searchParams.fromDate).getTime() &&
                toDate.getTime() >= new Date(this.searchParams.toDate).getTime() &&
                !event.booking
            ) {
                available = false;
                continue;
            }
        }

        return available;
    }

    private showResource(entry: PlanBoardPlace) {
        const place = this.places.find((p: Place) => p.placeId === entry.id);
        if (this.hideBlocked) {
            return this.hasFreeSpot(entry) && place.rentalState !== RentalState.Blocked;
        }

        return true;
    }
}
